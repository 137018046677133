<template>
	<div
		@click="handleClick"
		class="image main"
		:style="{ backgroundImage: `url('${parsedSrc}')` }"
		:aria-label="alt"
	/>
</template>

<script>
import InlineEditableImageMixin from "~/components/platform/inline-editing/InlineEditableImageMixin.js";

export default defineNuxtComponent({
	name: "LazyBackgroundImage",
	props: ["src", "alt", "eager"],
	mixins: [InlineEditableImageMixin],
	computed: {
		parsedSrc() {
			return this.src;
		},
	},
});
</script>

<style lang="scss" scoped>
.image {
	height: 100%;
}

.no-js .main {
	display: none;
}

picture,
picture img {
	max-width: 100%;
}
</style>
