export default {
	props: {
		nonEditable: {
			type: Boolean,
		},
	},
	computed: {
		editable() {
			return this.$config.public.isPreview && !this.nonEditable && !this.$store.state.selectTriggerElement;
		},
	},
	methods: {
		handleClick(event) {
			if (this.editable) {
				event.stopPropagation();
				window.parent.vue.$store.dispatch("preview/getImage", {
					value: {
						src: this.src,
						alt: this.alt,
					},
					callback: (result) => {
						result.src = "/binaries/" + result.src.split("/binaries/")[1];
						this.$el.dispatchEvent(
							new CustomEvent("contentChanged", {
								detail: {
									oldValue: {
										src: this.src,
										alt: this.alt,
									},
									unknownProp: true,
									value: result,
								},
								bubbles: true,
								composed: true,
							}),
						);
					},
				});
			}
		},
	},
};
